import React from "react";
import {motion} from "framer-motion";

const ZoomCard = (props) => {
    const { image, text, delay} = props;
    return (
        <motion.div transition={{delay: 0.2 + delay, duration: 0.7}} animate={{opacity: 1, scale: 1}} initial={{opacity:0,scale: 0}} className={"mb-5 shadow-md m-3"}>
            <div
                className={"overflow-hidden w-full xl:h-56 lg:h-72 md:h-64 sm:h-72 xs:h-52 h-48"}
            >
                <div
                    className={
                        "bg-epg-white transition  duration-1000 ease-in-out overflow-hidden transform hover:-translate hover:scale-110"
                    }
                >
                    <motion.img
                        transition={{delay: 0.4 + delay}} animate={{opacity: 1}} initial={{opacity:0}}
                        className={
                            " sm:bg-epg-yellow object-fill object-center w-full sm:mx-auto"
                        }
                        src={image}
                        alt="BigCo Inc. logo"
                    />
                </div>
            </div>
            <div className={"h-20 bg-epg-yellow flex items-center justify-center"}>
                <p className={"font-exo2 font-semibold text-xl text-epg-gray"}>
                    {text}
                </p>
            </div>
        </motion.div>
    );
};

export default ZoomCard
