import * as React from "react";
import { Helmet } from "react-helmet";
import PageLayout from "../../PageLayout";
import img1 from "../../images/services/1.jpg";
import img2 from "../../images/services/2.jpg";
import img3 from "../../images/services/3.jpg";
import img4 from "../../images/services/4.jpg";
import img5 from "../../images/services/5.jpg";
import img6 from "../../images/services/6.jpg";
import img7 from "../../images/services/7.jpg";
import ZoomCard from "../../components/ZoomCard";
import HowWeCanHelp from "../../components/HowWeCanHelp";
import SectionLayout from "../../SectionLayout";
import { Link } from "gatsby";
const Services = () => {
  return (
    <PageLayout
      noButton
      title={"Our Services"}
      subtitle={"Reliable power generation for the Territory"}
      navSize={"h-96"}
      titleStyles={"xl:mt-12 lg:mt-16 md:mt-16 sm:mt-20 xs:mt-20 mt-12"}
    >
      <Helmet>
        <title>EPG Solutions | Our Services</title>
        <meta
          name="description"
          content="Reliable power generation for the Northern Territory including design, construct, install, commission and servicing infrastructure."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <SectionLayout bgColor={"bg-epg-white"}>
        <div className={"py-5"}>
          <p className={"mt-5 leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black px-3"}>
            At E-P-G Solutions, we provide a wide range of services to the
            electrical power generation industry. Whether you need a small
            back-up generator system or a complete power station, we have the
            knowledge and the experience to get it done.
          </p>
          <p className={"mt-5 leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black px-3"}>
            We can design, construct, install, commission and service
            infrastructure that will meet the challenges of generating power in
            the most remote locations. Our services are delivered on-site and we
            can visit any remote community in the Northern Territory.
          </p>
          <p className={"my-5 leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black px-3"}>
            Our most popular services include:
          </p>
          <div className={"grid md:grid-cols-2 xl:grid-cols-3 mt-10"}>
            <Link to={"power-generation"}>
              <ZoomCard delay={0.2} image={img1} text={"Power Generation"} />
            </Link>
            <Link to={"electrical"}>
              <ZoomCard delay={0.4} image={img2} text={"Electrical"} />
            </Link>
            <Link to={"switchboards"}>
              <ZoomCard delay={0.6} image={img3} text={"Switchboards"} />
            </Link>
            <Link to={"water-management"}>
              <ZoomCard delay={0.8} image={img4} text={"Water Management"} />
            </Link>
            <Link to={"install-service-repair"}>
              <ZoomCard delay={1} image={img5} text={"Install, Service & Repair"} />
            </Link>
            <Link to={"remote-services"}>
              <ZoomCard delay={1.2} image={img6} text={"Remote Services"} />
            </Link>
            <Link to={"test-protection"}>
              <ZoomCard delay={1.4} image={img7} text={"Test & Protection"} />
            </Link>
          </div>
          <p className={"my-10 px-3 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
            With years working across the Northern Territory, you can count on
            us to get the results you need.
          </p>
        </div>
      </SectionLayout>
      <HowWeCanHelp />
    </PageLayout>
  );
};

export default Services;
